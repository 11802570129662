.background {
  background: linear-gradient(#0a0a0a, #02588a);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.scrollText {
  font-size: 1.5rem;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  height: 20rem;
  width: 25%;
  text-align: center;
  border-radius: 10px;
}

.parallax {
  margin-right: 15%;
}

.sticky {
  margin-left: 15%;
  background-color: transparent /*rgb(135, 188, 222, 0.9);*/
}

.sticky img{
  max-width: 100%;
  height: auto;
}

.blue {
  background-color: #5b65ad;
  color: white;
}

.purple {
  background-color: #9d65d0;
}

@media (max-width: 750px) {
  .card {
    width: 40%;
  }

  .parallax {
    margin-right: 1.5rem;
  }

  .sticky {
    margin-left: 1.5rem;
  }
}
  
/* Buttons */
.buttons {
  position: absolute;
  /* top: 1%; */
  left: 0;
  width: 100vw;
  text-align: center;
  z-index: 1;
}

.canvas1 {
  background-color: rgb(184, 8, 8);
  /* z-index: 1; */
}

.buttonmenu {
  position: relative;
  width: 6%;
  height: 40px;
  /* margin: 10px; */
  display: inline-block;
  /* border-radius: 50px; */
  /* border: 1px solid white; */
  border: none;
  background: transparent;
  /* transition: 0.2s; */
  overflow: hidden;
  font-size: 18px;
  font-weight: 800;
  color: black;
  line-height: 32px;
  min-width: 200px;
  text-transform: capitalize;
  text-decoration: none;
  text-shadow: 0 0 8px #fff;
}

.buttonmenu::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50px;
  transition: 0.2s;
}

.buttonmenu:nth-child(1)::before {
  content: 'Home';
}
.buttonmenu:nth-child(2)::before {
  content: 'Company';
}
.buttonmenu:nth-child(3)::before {
  content: 'Services';
}

/* .buttonmenu:hover::before {
  color: white;
  box-shadow: inset 10px 10px 15px white, 
              inset -10px -10px 15px white, 
              inset -10px 10px 15px white, 
              inset 10px -10px 15px white;
  text-shadow: 5px 5px 5px black, -5px -5px 5px black, -5px 5px 5px black, 5px -5px 5px black;
} */

.buttonmenu:hover {
  box-shadow: 0 0 5px black, 
              0 0 5px black, 
              0 0 5px black, 
              0 0 5px black;
  border: 4px solid white;
  background: white;
}